export const GLOBAL_CSS_DATA_ATTR = "data-mvt-global-css"

export const addGlobalCSS = (css: string) => {
  const style = document.createElement("style")
  style.textContent = css
  style.setAttribute(GLOBAL_CSS_DATA_ATTR, "true")
  document.head.appendChild(style)
}

export const addGlobalCode = (html: string, head = true) => {
  const container = document.createElement("div")
  container.innerHTML = html

  const appendTo = head ? document.head : document.body

  Array.from(container.children).forEach(node => {
    if (node.tagName === "SCRIPT") {
      const script = document.createElement("script")

      Array.from(node.attributes).forEach(attr =>
        script.setAttribute(attr.name, attr.value)
      )

      script.textContent = node.textContent
      appendTo.appendChild(script)
    } else {
      appendTo.appendChild(node)
    }
  })
}
